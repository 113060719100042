//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import KButton from 'kolibri.coreVue.components.KButton';
import KGrid from 'kolibri.coreVue.components.KGrid';
import KGridItem from 'kolibri.coreVue.components.KGridItem';

export default {
  name: 'CoreBanner',
  components: { KButton, KGrid, KGridItem },
  mixins: [themeMixin],
  data() {
    return {
      bannerClosed: false,
    };
  },
  methods: {
    toggleBanner() {
      this.bannerClosed = !this.bannerClosed;
    },
  },
  $trs: {
    openButton: 'More Info',
    closeButton: 'Close',
  },
};

