//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';

export default {
  name: 'InteractionItem',
  mixins: [themeMixin],
  props: {
    interaction: {
      type: Object,
      required: true,
    },
    selected: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    isAnswer() {
      return this.interaction.type === 'answer';
    },
    isHint() {
      return this.interaction.type === 'hint';
    },
    isError() {
      return this.interaction.type === 'error';
    },
    svgItemBorder() {
      return {
        borderBottom: `2px solid ${this.$themeTokens.text}`,
      };
    },
  },
};

