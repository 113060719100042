//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';

/**
 * Used for toggling boolean user input
 */
export default {
  name: 'KCheckbox',
  mixins: [themeMixin],
  props: {
    /**
     * Label
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Whether to show label
     */
    showLabel: {
      type: Boolean,
      default: true,
    },
    /**
     * Checked state
     */
    checked: {
      type: Boolean,
      default: false,
    },
    /**
     * Indeterminate state, overrides checked state
     */
    indeterminate: {
      type: Boolean,
      default: false,
    },
    /**
     * Disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    isCurrentlyChecked: false,
    isCurrentlyIndeterminate: false,
    isActive: false,
  }),
  computed: {
    id() {
      return `k-checkbox-${this._uid}`;
    },
    blank() {
      return {
        fill: this.disabled ? this.$themeTokens.textDisabled : this.$themeTokens.annotation,
      };
    },
    notBlank() {
      return {
        fill: this.disabled ? this.$themeTokens.textDisabled : this.$themeTokens.primary,
      };
    },
    activeOutline() {
      return this.isActive ? this.$coreOutline : {};
    },
    labelStyle() {
      return {
        color: this.disabled ? this.$themeTokens.textDisabled : '',
      };
    },
  },
  watch: {
    checked(newCheckedState) {
      this.isCurrentlyChecked = newCheckedState;
    },
    indeterminate(newIndeterminateState) {
      this.isCurrentlyIndeterminate = newIndeterminateState;
    },
  },
  created() {
    this.isCurrentlyChecked = this.checked;
    this.isCurrentlyIndeterminate = this.indeterminate;
  },
  methods: {
    toggleCheck(event) {
      if (!this.disabled) {
        this.isCurrentlyChecked = !this.isCurrentlyChecked;
        this.$refs.kCheckboxInput.focus();
        this.isCurrentlyIndeterminate = false;
        /**
         * Emits change event
         */
        this.$emit('change', this.isCurrentlyChecked, event);
      }
    },
    markInactive() {
      this.isActive = false;
      /**
       * Emits blur event, useful for validation
       */
      this.$emit('blur');
    },
  },
};

