//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapGetters, mapState } from 'vuex';
import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import UiToolbar from 'kolibri.coreVue.components.UiToolbar';
import UiIconButton from 'kolibri.coreVue.components.UiIconButton';
import CoreMenu from 'kolibri.coreVue.components.CoreMenu';
import CoreMenuOption from 'kolibri.coreVue.components.CoreMenuOption';
import UserTypeDisplay from 'kolibri.coreVue.components.UserTypeDisplay';
import UiButton from 'keen-ui/src/UiButton';
import navComponents from 'kolibri.utils.navComponents';
import { NavComponentSections } from 'kolibri.coreVue.vuex.constants';
import navComponentsMixin from '../mixins/nav-components';
import LogoutSideNavEntry from './LogoutSideNavEntry';

export default {
  name: 'AppBar',
  components: {
    UiToolbar,
    UiIconButton,
    CoreMenu,
    UiButton,
    CoreMenuOption,
    LogoutSideNavEntry,
    UserTypeDisplay,
  },
  mixins: [navComponentsMixin, themeMixin],
  props: {
    title: {
      type: String,
      required: true,
    },
    height: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      userMenuDropdownIsOpen: false,
    };
  },
  computed: {
    ...mapGetters(['isUserLoggedIn', 'getUserKind']),
    ...mapState({
      username: state => state.core.session.username,
    }),
    menuOptions() {
      return navComponents
        .filter(component => component.section === NavComponentSections.ACCOUNT)
        .filter(this.filterByRole);
    },
  },
  created() {
    window.addEventListener('click', this.handleWindowClick);
  },
  beforeDestroy() {
    window.removeEventListener('click', this.handleWindowClick);
  },
  methods: {
    handleWindowClick(event) {
      if (
        !this.$refs.userMenuDropdown.$el.contains(event.target) &&
        !this.$refs.userMenuButton.$el.contains(event.target) &&
        this.userMenuDropdownIsOpen
      ) {
        this.userMenuDropdownIsOpen = false;
      }
      return event;
    },
    handleChangeLanguage() {
      this.$emit('showLanguageModal');
      this.userMenuDropdownIsOpen = false;
    },
  },
  $trs: {
    openNav: 'Open site navigation',
    userTypeLabel: 'User type',
    languageSwitchMenuOption: 'Change language',
    userMenu: 'User menu',
  },
};

