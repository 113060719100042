//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';

/**
 * Used to display all options
 */
export default {
  name: 'KRadioButton',
  mixins: [themeMixin],
  model: {
    prop: 'currentValue',
  },
  props: {
    /**
     * Label
     */
    label: {
      type: String,
      required: true,
    },
    /**
     * Description for label
     */
    description: {
      type: String,
      required: false,
    },
    /**
     * Value that is currently assigned via v-model
     */
    currentValue: {
      type: [String, Number, Boolean],
      required: true,
    },
    /**
     * Unique value of this particular radio button
     */
    value: {
      type: [String, Number, Boolean],
      required: true,
    },
    /**
     * Disabled state
     */
    disabled: {
      type: Boolean,
      default: false,
    },
    /**
     * Autofocus on mount
     */
    autofocus: {
      type: Boolean,
      default: false,
    },
  },
  data: () => ({
    active: false,
  }),
  computed: {
    isChecked() {
      return this.value.toString() === this.currentValue.toString();
    },
    id() {
      return `${this._uid}`;
    },
    activeStyle() {
      // setting opacity to 0 hides input's default outline
      return this.active ? this.$coreOutline : {};
    },
    disabledStyle() {
      return this.disabled ? { fill: this.$themeTokens.textDisabled } : {};
    },
  },

  methods: {
    /**
     * @public
     */
    focus() {
      this.$refs.input.focus();
    },
    update(event) {
      /**
       * Emits change event
       */
      this.$emit('change', this.isChecked, event);

      // emitting input, resolves browser compatibility issues
      // with v-model's @input default and <input type=radio>
      this.$emit('input', this.value);
    },
  },
};

