//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';

export default {
  name: 'CoreMenu',
  mixins: [themeMixin],
  props: {
    // Whether to show if links are currently active
    showActive: {
      type: Boolean,
      default: true,
    },
    hasSecondaryText: {
      type: Boolean,
      default: false,
    },
    containFocus: {
      type: Boolean,
      default: false,
    },
    raised: {
      type: Boolean,
      default: false,
    },
  },
  provide() {
    return {
      showActive: this.showActive,
    };
  },
  computed: {
    classes() {
      return {
        'is-raised': this.raised,
        'has-secondary-text': this.hasSecondaryText,
      };
    },
  },

  methods: {
    redirectFocus(e) {
      e.stopPropagation();
      this.$el.querySelector('.ui-menu-option').focus();
    },
  },
};

