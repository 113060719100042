//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import KGrid from 'kolibri.coreVue.components.KGrid';
import KGridItem from 'kolibri.coreVue.components.KGridItem';
import ProgressIcon from 'kolibri.coreVue.components.ProgressIcon';
import ElapsedTime from 'kolibri.coreVue.components.ElapsedTime';
import KLabeledIcon from 'kolibri.coreVue.components.KLabeledIcon';
import KIcon from 'kolibri.coreVue.components.KIcon';

export default {
  name: 'PageStatus',
  components: {
    KGrid,
    KGridItem,
    ProgressIcon,
    ElapsedTime,
    KIcon,
    KLabeledIcon,
  },
  mixins: [themeMixin],
  props: {
    userName: {
      type: String,
      required: true,
    },
    questions: {
      type: Array,
      default: () => [],
    },
    completed: {
      type: Boolean,
      default: false,
    },
    completionTimestamp: { type: Date },
    contentName: {
      type: String,
      required: true,
    },
  },
  computed: {
    questionsCorrect() {
      return this.questions.reduce((a, q) => a + (q.correct === 1 ? 1 : 0), 0);
    },
    score() {
      return this.questions.reduce((a, q) => a + q.correct, 0) / this.questions.length || 0;
    },
    progress() {
      // Either return in completed or in progress
      return this.completed ? 1 : 0.1;
    },
    progressIconLabel() {
      if (this.completed) {
        return this.$tr('completed');
      } else if (this.completed !== null) {
        return this.$tr('inProgress');
      } else {
        return this.$tr('notStarted');
      }
    },
  },
  $trs: {
    title: `'{name}' performance`,
    overallScore: 'Overall score',
    questionsCorrectLabel: 'Questions correct',
    questionsCorrectValue: '{correct, number} out of {total, number}',
    completed: 'Completed',
    inProgress: 'In progress',
    notStarted: 'Not started',
  },
};

