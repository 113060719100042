//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';

const boolean = {
  type: Boolean,
  default: false,
};

export default {
  name: 'KIcon',
  mixins: [themeMixin],
  props: {
    /**
     * color to apply to the icon
     */
    color: {
      type: String,
      required: false,
    },
    // tracking
    correct: boolean,
    helpNeeded: boolean,
    hint: boolean,
    incorrect: boolean,
    inProgress: boolean,
    mastered: boolean,
    notStarted: boolean,
    rectified: boolean,
    // coaching
    coach: boolean,
    lesson: boolean,
    question: boolean,
    quiz: boolean,
    // content
    app: boolean,
    audio: boolean,
    channel: boolean,
    doc: boolean,
    exercise: boolean,
    topic: boolean,
    video: boolean,
    html5: boolean,
    // users
    classroom: boolean,
    group: boolean,
    people: boolean,
    permission: boolean,
    person: boolean,
    // misc
    dot: boolean,
    error: boolean,
    // UI
    back: boolean,
    forward: boolean,
    clear: boolean,
    dropdown: boolean,
    language: boolean,
    logout: boolean,
    menu: boolean,
    search: boolean,
  },
  computed: {
    style() {
      if (this.color) {
        return { fill: this.color };
      }
      return { fill: this.$themeTokens.text };
    },
    flip() {
      return this.isRtl ? 'rtl-icon' : null;
    },
  },
};

