//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';

export default {
  name: 'KBottomAppBar',
  mixins: [themeMixin],
  props: {
    maxWidth: {
      type: Number,
      default: 960,
    },
  },
  computed: {
    innerStyle() {
      if (this.maxWidth) {
        return { maxWidth: `${this.maxWidth}px` };
      }
      return null;
    },
  },
};

