import { render, staticRenderFns } from "./CoreMenuOption.vue?vue&type=template&id=57c02271&scoped=true&"
import script from "./CoreMenuOption.vue?vue&type=script&lang=js&"
export * from "./CoreMenuOption.vue?vue&type=script&lang=js&"
import style0 from "./CoreMenuOption.vue?vue&type=style&index=0&id=57c02271&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "57c02271",
  null
  
)

export default component.exports