//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import responsiveElement from 'kolibri.coreVue.mixins.responsiveElement';
import InteractionItem from './InteractionItem';

export default {
  name: 'InteractionList',
  components: { InteractionItem },
  mixins: [responsiveElement],
  props: {
    interactions: {
      type: Array,
      required: true,
    },
    selectedInteractionIndex: {
      type: Number,
      required: true,
    },
  },
  computed: {
    interactionsMessage() {
      const numAttempts = this.interactions.length;
      if (numAttempts === 0) {
        return this.$tr('noInteractions');
      }
      if (numAttempts > 1) {
        return this.$tr('currAnswer', { value: this.selectedInteractionIndex + 1 });
      }
      return '';
    },
  },
  methods: {
    setCurrentInteractionIndex(index) {
      this.$emit('select', index);
    },
    isSelected(index) {
      return Number(this.selectedInteractionIndex) === index;
    },
  },
  $trs: {
    currAnswer: 'Attempt {value, number, integer}',
    noInteractions: 'No attempts made on this question',
  },
};

