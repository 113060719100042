//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import themeMixin from 'kolibri.coreVue.mixins.themeMixin';
import UiIcon from 'keen-ui/src/UiIcon';
import UiPopover from 'keen-ui/src/UiPopover';
import UiTooltip from 'keen-ui/src/UiTooltip';
import KCircularLoader from 'kolibri.coreVue.components.KCircularLoader';

export default {
  name: 'KeenUiIconButton',

  components: {
    UiIcon,
    UiPopover,
    KCircularLoader,
    UiTooltip,
  },
  mixins: [themeMixin],

  props: {
    type: {
      type: String,
      default: 'primary', // 'primary' or 'secondary'
    },
    buttonType: {
      type: String,
      default: 'button',
    },
    color: {
      type: String,
      default: 'default', // 'default', 'primary', 'accent', 'green', 'orange', or 'red'
    },
    size: {
      type: String,
      default: 'normal', // 'small', normal', or 'large'
    },
    icon: String,
    ariaLabel: String,
    loading: {
      type: Boolean,
      default: false,
    },
    hasDropdown: {
      type: Boolean,
      default: false,
    },
    dropdownPosition: {
      type: String,
      default: 'bottom left',
    },
    openDropdownOn: {
      type: String,
      default: 'click', // 'click', 'hover', 'focus', or 'always'
    },
    tooltip: String,
    openTooltipOn: String,
    tooltipPosition: String,
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      dropdownOpen: this.openDropdownOn === 'always',
    };
  },

  computed: {
    classes() {
      return [
        `keen-ui-icon-button--type-${this.type}`,
        `keen-ui-icon-button--color-${this.color}`,
        `keen-ui-icon-button--size-${this.size}`,
        { 'is-loading': this.loading },
        { 'is-disabled': this.disabled || this.loading },
        { 'has-dropdown': this.hasDropdown },
        this.$computedClass({ ':focus': { ...this.$coreOutline, outlineOffset: '-4px' } }),
      ];
    },

    primaryType() {
      return this.type === 'primary';
    },
    primaryColor() {
      return this.color === 'primary';
    },
    buttonColor() {
      if (this.primaryColor && this.primaryType) {
        const style = {
          backgroundColor: this.$themeTokens.primary,
        };
        if (this.dropdownOpen) {
          style[':hover:not(.is-disabled)'] = {
            backgroundColor: this.$themeTokens.primaryDark,
          };
        }
        return style;
      } else if (this.primaryColor && !this.primaryType) {
        return {
          color: this.$themeTokens.primary,
          fill: this.$themeTokens.primary,
        };
      }

      return {};
    },
  },

  methods: {
    onClick(e) {
      this.$emit('click', e);
    },

    onDropdownOpen() {
      this.dropdownOpen = true;
      this.$emit('dropdown-open');
    },

    onDropdownClose() {
      this.dropdownOpen = false;
      this.$emit('dropdown-close');
    },

    /**
     * @public
     */
    openDropdown() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.open();
      }
    },

    /**
     * @public
     */
    // eslint-disable-next-line
    closeDropdown() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.close();
      }
    },

    /**
     * @public
     */
    toggleDropdown() {
      if (this.$refs.dropdown) {
        this.$refs.dropdown.toggle();
      }
    },
  },
};

