//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { mapState } from 'vuex';
import KExternalLink from 'kolibri.coreVue.components.KExternalLink';
import KModal from 'kolibri.coreVue.components.KModal';
import TechnicalTextBlock from './TechnicalTextBlock';

export default {
  name: 'ReportErrorModal',
  components: {
    KExternalLink,
    KModal,
    TechnicalTextBlock,
  },
  data() {
    return {
      // TODO Set offline variable via ping in mounted()?
      // Or via computed prop
      offline: false,
    };
  },
  computed: {
    ...mapState({
      error: state => state.core.error,
    }),
    forumLink() {
      return 'https://community.learningequality.org/c/support/kolibri';
    },
    emailAddress() {
      return 'info@learningequality.org';
    },
    emailAddressLink() {
      return `mailto:${this.emailAddress}`;
    },
  },
  $trs: {
    reportErrorHeader: 'Report Error',
    forumPrompt: 'Visit the community forums',
    // reall long
    forumUseTips:
      'Search the community forum to see if others encountered similar issues. If unable to find anything, paste the error details below into a new forum post so we can rectify the error in a future version of Kolibri.',
    forumPostingTips:
      'Include a description of what you were trying to do and what you clicked on when the error appeared.',
    emailPrompt: 'Send an email to the developers',
    emailDescription:
      'Contact the support team with your error details and we’ll do our best to help.',
    errorDetailsHeader: 'Error details',
    closeErrorModalButtomPrompt: 'Close',
    errorFileDenotation: 'error',
  },
};

