//
//
//
//
//
//
//
//
//
//
//
//
//


import responsiveWindow from 'kolibri.coreVue.mixins.responsiveWindow';
import themeMixin from 'kolibri.coreVue.mixins.themeMixin';

export default {
  name: 'KPageContainer',
  mixins: [responsiveWindow, themeMixin],
  props: {
    // If true, removes the padding inside the container
    noPadding: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    classes() {
      return {
        small: this.windowIsSmall,
        'no-padding': this.noPadding,
      };
    },
  },
};

