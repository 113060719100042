//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


export default {
  name: 'KLabeledIcon',
  components: {},
};

